import {mapActions} from 'vuex';

export default {
	i18n: require('./i18n').default,
	name: 'SubscribeItem',
	props: {
		info: Object,
		type: String,
		showEditSubscription: false
	},
	data() {
		return {
			switcherState: undefined,
		};
	},
	mounted() {
		this.switcherState = this.info?.active;
		if (this.info?.searchString && this.info?.searchString.indexOf('dealer.id') === -1 && this.type === 'used') {
			this.showEditSubscription = true;
		}
	},
	computed: {
		description() {
			return this.info?.description || '';
		},
		channelLogo() {
			return this.info?.channel?.label ? `logo-${this.info.channel.label}` : '';
		},
		channelName() {
			return this.info?.channel?.name || '';
		},
		carCount() {
			return this.info?.carCount || '';
		},
		showHotLabel() {
			return Number(this.info?.carCount) > 0;
		},
		searchLink() {
			switch (this.type) {
				case 'new':
					return this.info?.searchString ? `/newauto/search?${this.info?.searchString }` : 'javascript:void(0)';
				case 'used':
				default:
					return this.info?.searchString ? `/search?${this.info?.searchString }` : 'javascript:void(0)';
			}
		},
		id() {
			return this.info?.id || 0;
		},
		channelId() {
			return this.info?.channel?.id || 0;
		},
		hasSwitcher() {
			return this.info?.active !== undefined && this.info?.active !== null;
		},
	},
	watch: {
		switcherState(data) {
			if (Boolean(data) !== this.info?.active) {
				const payload = {
					channel: this.channelId,
					id: this.id,
					active: Boolean(data),
					projectId: this.type === 'used' ? 1 : 2,
				};

				this.gaEvent('Subscriptions_Page', 'Switcher', 'Subscribe');

				this.editSub(payload).then(() => {
					this.info.active = Boolean(data);
				});
			}
		}
	},
	methods: {
		...mapActions({
			editSub: 'search/state2/editSub',
		}),
		editSubscribe() {
			location.href = this.type === 'used' ?
				`/advanced-search?editSubscribe=${this.info?.id}&channelId=${this.info?.channel?.id}&${this.info.searchString}` :
				'/newauto/category-legkovie/';
		},
		deleteSubscribe() {
			this.$emit('deletePopUpToggle', this.info);
		},
	},
};
