import { mapActions } from 'vuex';
import Vue from 'vue';

import Item from './Item/index.vue';
import Pagination from '../../Common/Pagination/index.vue';
import Popup from '../../Common/Popup.vue';
import Evaluation from '../components/Evaluation/index.vue';

export default {
	i18n: require('./i18n').default,
	name: 'Subscriptions',
	data() {
		return {
			used: {
				count: undefined,
				pages: undefined,
				page: 1,
				subscribes: {},
				subscribesAvailable: undefined,
			},
			new: {
				count: undefined,
				pages: undefined,
				page: 1,
				subscribes: {},
				subscribesAvailable: undefined,
			},
			limit: 10,
			type: 'used',
			loading: true,
			showDeletePopup: false,
			deleteSubInfo: {},
			isShowPopup: false,
		};
	},
	components: {
		Item,
		Pagination,
		Popup,
		Evaluation,
	},
	mounted() {
		if (_TARGET_ === 'client') {
			this.fetchSubscribesInfoWrapper();
			this.isShowPopup = Number(this.$route.query.userconfirmubscribe) === 1;
		}
	},
	methods: {
		...mapActions({
			fetchSubscribesInfo: 'cabinet/subscriptionsPage/fetchSubscribes',
			onDeleteSubscribe: 'cabinet/subscriptionsPage/deleteSubscribe'
		}),
		fetchSubscribesInfoWrapper() {
			if (this.needToFetchSubscribe) {
				const params = {
					limit: this.limit,
					projectId: this.type === 'used' ? 1 : 2,
					skip: this.page * this.limit - this.limit,
				};
				this.loading = true;
				this.fetchSubscribesInfo(params)
					.then((subscribes) => {
						this.count = subscribes.count;
						this.subscribes = subscribes.data;
						this.pages = Math.ceil(this.count / this.limit);
						this.subscribesAvailable = Boolean(this.count);
						this.loading = false;
					});
			}
		},
		subsTypeChange(type) {
			this.type = type;
			this.fetchSubscribesInfoWrapper();
		},
		onChangeLimit(newLimit) {
			this.limit = newLimit;
			this.cleanSubs();
			this.fetchSubscribesInfoWrapper();
		},
		onChangePage(newPage) {
			this.page = newPage;
			this.fetchSubscribesInfoWrapper();
		},
		cleanSubs() {
			if (this.type === 'used') {
				Vue.set(this.used, 'subscribes', []);
			} else {
				Vue.set(this.new, 'subscribes', []);
			}
		},
		deletePopUpToggle(subInfo) {
			if (this.deleteSubInfo.id) {
				this.deleteSubInfo = {};
			} else {
				this.deleteSubInfo = subInfo;
			}

			this.showDeletePopup = !this.showDeletePopup;
		},
		deleteSubscribe() {
			const params = {
				projectId: this.type === 'used' ? 1 : 2,
				channelId: this.deleteSubInfo.channel.id,
				subsId: this.deleteSubInfo.id,
			};

			this.onDeleteSubscribe(params)
				.then((data) => {
					console.log('deleteSubscribe result', data);
					this.cleanSubs();
					this.fetchSubscribesInfoWrapper();
					this.deletePopUpToggle();
				})
				.catch((error) => {
					console.error('subscriptions/subspage deleteSubscribe error', error.message);
					this.deletePopUpToggle();
				});
		},
		closePopup() {
			this.isShowPopup = false;
		}
	},
	computed: {
		needToFetchSubscribe() {
			return !(this.type === 'used' && this.used.subscribes[`page${this.page}`] && this.used.subscribes[`page${this.page}`].length ||
					this.type === 'new' && this.new.subscribes[`page${this.page}`] && this.new.subscribes[`page${this.page}`].length);
		},
		subscribesAvailable: {
			get: function () {
				if (this.type === 'used' && typeof this.used.subscribesAvailable === 'undefined') {
					return true;
				} else {
					return Boolean(this.used.subscribesAvailable);
				}
			},
			set: function (isAvailable) {
				if (this.type === 'used') {
					Vue.set(this.used, 'subscribesAvailable', isAvailable);
				} else {
					Vue.set(this.new, 'subscribesAvailable', isAvailable);
				}
			}
		},
		count: {
			get: function () {
				return this.type === 'used' ? this.used.count : this.new.count;
			},
			set: function (newCount) {
				if (this.type === 'used') {
					Vue.set(this.used, 'count', newCount);
				} else {
					Vue.set(this.new, 'count', newCount);
				}
			}
		},
		pages: {
			get: function () {
				return this.type === 'used' ? this.used.pages : this.new.pages;
			},
			set: function (newPages) {
				if (this.type === 'used') {
					Vue.set(this.used, 'pages', newPages);
				} else {
					Vue.set(this.new, 'pages', newPages);
				}
			}
		},
		page: {
			get: function () {
				return this.type === 'used' ? this.used.page : this.new.page;
			},
			set: function (newPage) {
				if (this.type === 'used') {
					Vue.set(this.used, 'page', newPage);
				} else {
					Vue.set(this.new, 'page', newPage);
				}
			}
		},
		subscribes: {
			get: function () {
				return this.type === 'used' ? this.used.subscribes[`page${this.page}`] : this.new.subscribes[`page${this.page}`];
			},
			set: function (newSubscribes) {
				if (this.type === 'used') {
					Vue.set(this.used.subscribes, `page${this.page}`, newSubscribes);
				} else {
					Vue.set(this.new.subscribes, `page${this.page}`, newSubscribes);
				}
			},
		},
		createNewSubscribeLink() {
			return this.type === 'used' ? '/advanced-search/?newSubscribe=1' : '/newauto/search/?categoryId=1';
		},
	},
};
