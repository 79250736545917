export default {
	messages: {
		ru: {

		},
		uk: {

		}
	}
};
