export default {
	messages: {
		ru: {
			'Вы уверены, что хотите удалить подписку?': 'Вы уверены, что хотите удалить подписку?',
			'После этого мы не сможем отправлять вам свежедобавленные авто с этими параметрами.': 'После этого мы не сможем отправлять вам свежедобавленные авто с этими параметрами.',
			'Да, удалить': 'Да, удалить',
			'Нет, оставить': 'Нет, оставить',
			'Сейчас у вас нет активных подписок. Вы можете создать первую.': 'Сейчас у вас нет активных подписок. Вы можете создать первую.',
			'Создать новый профиль рассылки': 'Создать новый профиль рассылки',
			'Создать подписку': 'Создать подписку',
			'Мои подписки': 'Мои подписки',
			'Новые авто': 'Новые авто',
			'Поздравляем!': 'Поздравляем!',
			'Подписка подтверждена': 'Подписка подтверждена',
		},
		uk: {
			'Вы уверены, что хотите удалить подписку?': 'Ви впевнені, що хочете видалити підписку?',
			'После этого мы не сможем отправлять вам свежедобавленные авто с этими параметрами.': 'Після цього ми не зможемо надсилати вам свіжододані авто з цими параметрами.',
			'Да, удалить': 'Так, видалити',
			'Нет, оставить': 'Ні, залишити',
			'Сейчас у вас нет активных подписок. Вы можете создать первую.': 'Зараз у вас немає активних підписок. Ви можете створити першу.',
			'Создать новый профиль рассылки': 'Створити новий профіль розсилки',
			'Создать подписку': 'Створити підписку',
			'Мои подписки': 'Мої підписки',
			'Новые авто': 'Нові авто',
			'Поздравляем!': 'Вітаємо!',
			'Подписка подтверждена': 'Підписка підтверджена'
		}
	}
};
